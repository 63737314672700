import {
  filter,
  finalize,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  HideLoaderAction,
  ShowLoaderAction,
} from '../loader/load.actioins';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { patch } from '@ngxs/store/operators';
import {
  Action,
  ActionCompletion,
  Actions,
  createPickSelector,
  createPropertySelectors,
  createSelector,
  ofActionCompleted,
  ofActionSuccessful,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  createDeepPropSelector,
  getDeepPatch,
} from '@shared/utils/state.util';
import { cloneDeep, forEach } from 'lodash';
import * as _ from 'lodash';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import {
  AddLoaLevel,
  ChangeLoa,
  ClearMemoPasswordPopup,
  CloseMemoPasswordPopup,
  CreateMemo,
  DdocSettingsUpdateMemo,
  DropdownGetApiUpdateDept,
  DropdownGetApiUpdateMemo,
  ErrorNotification,
  MemoCarbonAccountingUpdateMemo,
  MemoCustomLoaAddLoaLevel,
  MemoCustomLoaChangeLoa,
  MemoCustomLoaSetLoa,
  MemoUploadAttachmentUpdateMemo,
  PreviewMemo,
  RemoveLoaLevel,
  ResetLoa,
  ResetState,
  SaveItemMemo,
  SaveMemo,
  SavePristineMemo,
  SetInitMemo,
  SetLoa,
  SetWidgets,
  ShowMemoPasswordPopup,
  UpdateLoaLevel,
  UpdateMemo,
  UpdateMemoDetail,
  UpdateWidget,
  UploadAttachment,
  UploadEmissionEvidenceFile,
  UploadMemoAddLoaLevel,
  UploadSTCAttachment,
  UploadMemoSetCustomLoaLevels,
  UploadMemoSetLoa,
  UploadMemoTypeSelectUpdateMemoTemplate,
  UploadMemoTypeSelectUpdateUploadMemoType,
  UploadMemoUploadMultiPDF,
  UploadMemoUploadPDF,
  WidgetPdfHandlerChangeLoa,
  WidgetPdfHandlerUpdateMemo,
} from './memo.actions';
import {
  Memo,
  MemoCreationData,
  MemoPasswordPopUp,
} from './memo.model';
import {
  MemoDetail,
  UploadMemoPatch,
} from 'src/app/modules/memos/model/memo.model';
import { EMPTY, Observable, Subject } from 'rxjs';
import { featureFlag } from '../../../environments/environment';
import {
  DDocSigningStatus,
  LoaDetail,
  Signer,
} from '../../modules/loa/shared/loa.model';
import { LoaService } from '../../modules/loa/shared/loa.service';
import { Params } from '@angular/router';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { LoaChangeEvent } from 'src/app/modules/memos/components/upload-memos/loa-customizer/loa-customizer.component';

@State<Partial<MemoDetail>>({
  name: 'memoCreationData',
  defaults: {},
})
@Injectable({
  providedIn: 'root',
})
export class MemoCreationState implements OnDestroy {
  isInitCompleted$: Observable<ActionCompletion>;
  loaChangeObs: Observable<LoaChangeEvent>;

  private action$ = inject(Actions);
  private destroy$ = new Subject<void>();
  private loaChange$ = new Subject<LoaChangeEvent>();
  private loaService = inject(LoaService);

  // TODO: Refactor to method
  getUploadFileApi = (createMemoRes: any, memoType: string) => {
    const uploadFileApi: any[] = [
      new UploadAttachment(createMemoRes.id),
    ];
    if (
      featureFlag.carbon_accounting_enable &&
      memoType === 'purchase_request'
    ) {
      const purchaseId = createMemoRes.purchase_request?.id;
      uploadFileApi.push(new UploadEmissionEvidenceFile(purchaseId));
    }
    if (memoType === 'upload') {
      const uploadId = createMemoRes.upload.id;
      uploadFileApi.push(
        new UploadMemoUploadPDF(uploadId, createMemoRes.id),
      );
      uploadFileApi.push(new UploadMemoUploadMultiPDF(uploadId));
    }
    return uploadFileApi;
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private memoService: MemoService,
    private alert: AlertService,
    private translate: TranslateService,
    private store: Store,
    private spinner: SpinnerService,
  ) {
    this.loaChangeObs = this.loaChange$.asObservable();
    this.isInitCompleted$ = this.action$.pipe(
      ofActionCompleted(SetInitMemo),
      takeUntil(this.destroy$),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.isInitCompleted$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @Selector()
  static memoCreationData(state: MemoDetail): MemoDetail {
    return state;
  }

  @Action([UploadMemoAddLoaLevel, MemoCustomLoaAddLoaLevel])
  addLoaLevel(
    ctx: StateContext<Memo>,
    action: AddLoaLevel,
  ): Observable<void> | any {
    const state = ctx.getState();
    const pushLevelFn = (
      underState: Partial<Memo> | any,
    ): Partial<MemoDetail> => {
      const presentLevel = underState.loa_detail.levels;
      const defaultApprover: Partial<Signer> = {
        is_delegate: false,
        person: null,
        person_detail: null,
      };
      const newLevel = {
        level: presentLevel.length + 1,
        name: action.name,
        members: [defaultApprover],
        signature_required: true,
        min_approve_count: 1,
        count: 1,
        user_type: 'user',
      };
      if (featureFlag.ddoc) {
        /** Default ddoc_enable of the new level follow last level before */
        const loaLevels = presentLevel;
        const lastLevelDdocEnable =
          loaLevels[(loaLevels.length || 1) - 1]?.ddoc_enable;
        Object.assign(newLevel, {
          ddoc_enable: lastLevelDdocEnable || false,
        });
        /** fill default signing status */
        const defaultSigningStatus: DDocSigningStatus = {
          ddoc_use_26: lastLevelDdocEnable || false,
          ddoc_use_28: false,
          ddoc_certified_mode: false,
          ddoc_locked_mode: false,
        };
        Object.assign(defaultApprover, defaultSigningStatus);
      }
      const loaDetailPatch = {
        ...underState.loa_detail,
        levels: [...(underState.loa_detail.levels || []), newLevel],
        is_custom: true,
      };
      return { loa_detail: loaDetailPatch };
    };
    if (state.loa_detail == null) {
      return ctx.dispatch(new ResetLoa()).pipe(
        tap(() => {
          ctx.patchState(pushLevelFn(ctx.getState()));
        }),
      );
    }
    ctx.patchState(pushLevelFn(state));
  }

  getDepartmentChangeAction(): Observable<DropdownGetApiUpdateDept> {
    let department: MemoDetail['department'];
    return this.action$.pipe(
      ofActionSuccessful(DropdownGetApiUpdateDept),
      filter((action) => {
        const isDepartmentChanged = !_.isEqualWith(
          department,
          action.value,
          (obj1, obj2) => (obj1 == null && obj2 == null) || undefined,
        );
        return isDepartmentChanged;
      }),
      tap((action) => {
        department = action.value;
      }),
      share(),
    );
  }

  getSaveItemMemoAction(): Observable<SaveItemMemo> {
    return this.action$.pipe(ofActionSuccessful(SaveItemMemo));
  }

  /**  Create & Update Memo */
  @Action([SaveMemo, SetInitMemo])
  save(
    { setState }: StateContext<MemoCreationData<MemoDetail>>,
    { payload }: any,
  ): void {
    setState({
      ...payload,
    });
  }

  @Action([
    SaveItemMemo,
    MemoCarbonAccountingUpdateMemo,
    SavePristineMemo,
  ])
  saveItem(
    ctx: StateContext<Partial<MemoDetail>>,
    { value, name }: { value: any; name: string },
  ): void {
    const statePatch: Params = {
      [name]: value,
    };
    ctx.setState(patch(statePatch));
  }

  @Action([
    DdocSettingsUpdateMemo,
    DropdownGetApiUpdateDept,
    DropdownGetApiUpdateMemo,
    MemoUploadAttachmentUpdateMemo,
    UploadMemoTypeSelectUpdateUploadMemoType,
    UploadMemoTypeSelectUpdateMemoTemplate,
    WidgetPdfHandlerUpdateMemo,
  ])
  updateItem(
    ctx: StateContext<Partial<MemoDetail>>,
    action: UpdateMemoDetail,
  ): void {
    const state = getDeepPatch(action.key, action.value);
    ctx.setState(state);
  }

  @Action(RemoveLoaLevel)
  removeLoaLevel(
    ctx: StateContext<Memo>,
    action: RemoveLoaLevel,
  ): void {
    const state: any = ctx.getState();
    const loa = state.loa_detail;
    if (state.loa_detail.levels?.length < 1) {
      return;
    }
    const index = action.index ?? loa.levels.length - 1;
    const loaRemoved = loa.levels.splice(index, 1);
    // It does not patch with immutable because it change only level inside loa_detail.
    // To update loa_detail state without trigger loa_detail state change but
    // trigger by loaChange$ instead.
    ctx.patchState({
      loa_detail: loa,
    });
    this.loaChange$.next({
      levelIndex: index,
      actionType: 'removeLevel',
      snapshot: loaRemoved,
    });
  }

  @Action(ResetLoa)
  resetLoa(ctx: StateContext<Memo>): void {
    ctx.patchState({
      loa_detail: { levels: [] },
    });
  }

  @Action(ResetState)
  resetState({ setState }: StateContext<Partial<MemoDetail>>): void {
    setState({});
  }

  @Action(CreateMemo)
  createMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((createMemoRes) => {
        if (payload.memo_type === 'stc') {
          // Return the observable chain properly
          return this.store
            .dispatch(new UploadSTCAttachment(createMemoRes))
            .pipe(
              switchMap(() =>
                this.store.dispatch(
                  new UploadAttachment(createMemoRes.id),
                ),
              ),
              map(() => createMemoRes),
            );
        }
        // Return the observable chain for non-STC memo types
        const uploadFileApi = this.getUploadFileApi(
          createMemoRes,
          payload.memo_type,
        );
        return this.store
          .dispatch(uploadFileApi)
          .pipe(map(() => createMemoRes));
      }),
      tap((res) => {
        // re-attach attachment since updateMemo api don't send attachment back
        res.attachments = payload.attachments;
        this.store.dispatch(new SaveMemo(res));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        this.spinner.hide();
      }),
    );
  }

  @Action(PreviewMemo)
  previewMemo(
    { getState }: StateContext<MemoCreationData<any>>,
    { payload }: { payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.createMemo(payload).pipe(
      // ดูว่าทำไม่ถึงใช้ switchMap ที่ action 'UpdateMemo'
      // look at notes in action 'UpdateMemo' on why I use switchMap.
      switchMap((createMemoRes) => {
        if (payload.memo_type === 'stc') {
          // Return the observable chain properly
          return this.store
            .dispatch(new UploadSTCAttachment(createMemoRes))
            .pipe(
              switchMap(() =>
                this.store.dispatch(
                  new UploadAttachment(createMemoRes.id),
                ),
              ),
              map(() => createMemoRes),
            );
        }
        // Return the observable chain for non-STC memo types
        const uploadFileApi = this.getUploadFileApi(
          createMemoRes,
          payload.memo_type,
        );
        return this.store
          .dispatch(uploadFileApi)
          .pipe(map(() => createMemoRes));
      }),
      tap((res) => {
        this.store.dispatch(
          new SaveItemMemo(res.signed_document, 'signed_document'),
        );
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
      }),
    );
  }

  @Action([UploadMemoSetLoa, MemoCustomLoaSetLoa])
  updateLoa(ctx: StateContext<MemoDetail>, action: SetLoa): void {
    let loaPatch: Partial<MemoDetail> = {};
    const loa = action.loaGroup;
    if (loa == null) {
      loaPatch = { loa_detail: null, loa_group: null };
    } else {
      loaPatch = loa?.is_custom
        ? { loa_detail: loa, loa_group: null }
        : { loa_detail: _.cloneDeep(loa), loa_group: loa };
    }
    ctx.patchState(loaPatch);
  }

  @Action(UploadMemoSetCustomLoaLevels)
  setLoaLevels(
    ctx: StateContext<MemoDetail>,
    action: UploadMemoSetCustomLoaLevels,
  ): void {
    const loaDetailState = ctx.getState().loa_detail;
    ctx.patchState({
      loa_detail: loaDetailState && {
        ...loaDetailState,
        levels: [...action.loaLevels],
      },
    });
  }

  @Action(UpdateLoaLevel)
  updateLoaLevel(
    ctx: StateContext<Memo>,
    action: UpdateLoaLevel,
  ): void {
    ctx.setState(
      getDeepPatch(
        ['loa_detail', 'levels', action.index.toString()],
        action.updatedLoaLevel,
      ),
    );
    if (action.event) {
      this.loaChange$.next(action.event);
    }
  }

  @Action([WidgetPdfHandlerChangeLoa, MemoCustomLoaChangeLoa], {
    cancelUncompleted: true,
  })
  changeLoa(ctx: StateContext<MemoDetail>, action: ChangeLoa) {
    if (!action.loaId) {
      this.updateLoa(ctx, { loaGroup: null });
      return;
    }
    return this.loaService
      .getLOAData(action.loaId, {
        include_custom: true,
        ...(action.params || {}),
      })
      .pipe(
        tap({
          next: (data) => {
            this.updateLoa(ctx, { loaGroup: data as LoaDetail });
          },
        }),
      );
  }

  @Action(UpdateMemo)
  updateMemo(
    { getState }: StateContext<MemoCreationData<MemoDetail>>,
    { id, payload }: { id: number; payload: any },
  ): Observable<MemoDetail> {
    this.store.dispatch(new ShowLoaderAction());
    return this.memoService.updateMemo(id, payload).pipe(
      // TH: Note 1: ที่เราทำ switchMap ตรงนี้เพราะว่า ต้องการดูว่า action 'UploadFile' มันสำเร็จหรือเปล่า
      //       ถ้ามันไม่สำเร็จ ก็คนที่ subscribe function นี้ก็จะได้ event error กลับไปด้วย
      //     Note 2: ที่มีการ 'pipe(map(() => updateMemoRes))' ตอนท้ายก็เพราะต้องการให้ tap ด้านล่างมัน process
      //       response ของ updateMemo ไม่ใช่ response ของ UploadFile
      //
      // EN: Note 1: I use switchMap here because I want to wait for 'UploadFile' action finished.
      //       if it is failed, the error event to raised to everyone that subscribe to this function.
      //     Note 2: I use 'pipe(map(() => updateMemoRes))' in the end because I want 'tap' below to process
      //       response of 'updateMemo', not response of 'UploadFile'
      switchMap((createMemoRes) => {
        if (payload.memo_type === 'stc') {
          // Return the observable chain properly
          return this.store
            .dispatch(new UploadSTCAttachment(createMemoRes))
            .pipe(
              switchMap(() =>
                this.store.dispatch(
                  new UploadAttachment(createMemoRes.id),
                ),
              ),
              map(() => createMemoRes),
            );
        }
        // Return the observable chain for non-STC memo types
        const uploadFileApi = this.getUploadFileApi(
          createMemoRes,
          payload.memo_type,
        );
        return this.store
          .dispatch(uploadFileApi)
          .pipe(map(() => createMemoRes));
      }),
      tap((res) => {
        this.store.dispatch(
          new SaveItemMemo(res.signed_document, 'signed_document'),
        );
        this.store.dispatch(new SaveItemMemo(res.id, 'id'));
      }),
      finalize(() => {
        this.store.dispatch(new HideLoaderAction());
        this.spinner.hide();
      }),
    );
  }

  @Action(UploadAttachment)
  uploadAttachment(
    { getState }: StateContext<MemoDetail>,
    { id }: { id: number },
  ): Observable<any> {
    const payload = getState() as MemoDetail;
    if (!payload.attachments || payload.attachments.length === 0) {
      return EMPTY;
    }
    const fd = new FormData();
    fd.append('memo', id.toString());
    forEach(payload.attachments, (attachFile: any, index) => {
      const i = index + 1;
      if (attachFile.id) {
        fd.append(i.toString(), attachFile.id.toString());
      } else {
        const file = attachFile.file;
        file.fileEntry
          ? fd.append(i.toString(), file.fileEntry)
          : fd.append(i.toString(), file);
      }
    });
    return this.memoService.uploadMemoAttachment(fd).pipe(
      tap({
        next: (res: [] | any) => {
          const attachments = payload.attachments.filter(
            (file: any) => file.id,
          );
          if (res.length) {
            attachments.push(...res);
          }
          this.store.dispatch(
            new SaveItemMemo(attachments, 'attachments'),
          );
        },
      }),
    );
  }

  @Action(UploadEmissionEvidenceFile)
  uploadEmissionEvidenceFile(
    { getState }: StateContext<MemoCreationData<any>>,
    { purchaseId }: { purchaseId: number },
  ): Observable<any> {
    const file = getState().new_emission_evidence_file;
    if (!file) {
      return EMPTY;
    }
    const fd = new FormData();
    const fileToAppend = file.fileEntry || file;
    fd.append(
      'evidence_file',
      fileToAppend,
      file.name || 'relativePath',
    );
    return this.memoService
      .uploadEmissionEvidenceFile(purchaseId, fd)
      .pipe(
        tap({
          next: () => {
            this.store.dispatch(
              new MemoCarbonAccountingUpdateMemo(
                null,
                'new_emission_evidence_file',
              ),
            );
          },
        }),
      );
  }

  @Action(UploadSTCAttachment)
  UploadSTCAttachment(
    { getState }: StateContext<MemoCreationData<any>>,
    { items }: { items: any },
  ): Observable<any> {
    const payload = cloneDeep(getState());
    if (
      !payload.stc_attachments ||
      payload.stc_attachments.length === 0
    ) {
      return EMPTY;
    }
    const fd = new FormData();
    const stcId = items?.stc?.id;
    payload.stc_attachments.forEach((attachment: any) => {
      const newAttachments = attachment.files.filter(
        (file: any) => file.id === undefined,
      );
      newAttachments?.forEach((file: any) => {
        const fileToAppend = file.fileEntry || file;
        fd.append(
          attachment.control_name,
          fileToAppend,
          file.name || 'relativePath',
        );
      });
    });

    return this.memoService
      .uploadSTCAttachment(stcId, fd)
      .pipe(take(1));
  }

  @Action(UploadMemoUploadPDF)
  uploadMemoUploadPDF(
    { getState }: StateContext<any>,
    { uploadId, memoId }: { uploadId: number; memoId: number },
  ): Observable<unknown> {
    const currentPdfFile = (getState().upload as UploadMemoPatch)
      .uploaded_pdf;
    const previousPdfFile = (getState().pristineMemo as MemoDetail)
      ?.upload?.uploaded_pdf;
    const uploadFormData = new FormData();
    if (currentPdfFile == null && previousPdfFile) {
      return this.memoService.removeBlob({
        memo: memoId,
      });
    }
    const isFinalPdfFileChange =
      currentPdfFile && typeof currentPdfFile === 'object';
    if (isFinalPdfFileChange) {
      uploadFormData.append('uploaded_pdf', currentPdfFile);
      return this.memoService.uploadBlob(uploadId, uploadFormData);
    }
    return EMPTY;
  }

  @Action(UploadMemoUploadMultiPDF)
  uploadMemoUploadMultiPDF(
    { getState }: StateContext<any>,
    { uploadId }: { uploadId: number },
  ): Observable<any> {
    const uploadPdfFiles = (getState().upload as UploadMemoPatch)
      .multiUploadPdfUpdate;
    const multiUploadPdfDetail = (
      getState().upload as UploadMemoPatch
    ).multi_upload_pdf_detail;
    const uploadMultiFormData = new FormData();
    if (uploadPdfFiles) {
      uploadPdfFiles.multi_upload_pdf_files.forEach((file) => {
        uploadMultiFormData.append('multi_upload_pdf_files', file);
      });
      uploadMultiFormData.append(
        'multi_upload_pdf_details',
        JSON.stringify(uploadPdfFiles.multi_upload_pdf_details),
      );
    }
    const pristineMemo = getState().pristineMemo as MemoDetail;
    const isChanged =
      !!uploadPdfFiles ||
      pristineMemo?.upload?.multi_upload_pdf_detail
        ?.upload_file_details.length !==
        multiUploadPdfDetail?.upload_file_details?.length;
    return !isChanged
      ? EMPTY
      : this.memoService.uploadMultiBlob(
          uploadId,
          uploadMultiFormData,
        );
  }

  /**  Check Error Message */
  @Action(ErrorNotification)
  errorNotification(
    { getState }: StateContext<MemoCreationData<any>>,
    { error }: any,
  ): void {
    if (error.error && error.error.en) {
      this.alert.error(
        error.error[this.translate.currentLang].join('\n'),
      );
    } else if (error.error && error.error.non_field_errors) {
      const non_field_errors: string[] = error.error.non_field_errors;
      this.alert.error(non_field_errors.join('\n'));
    } else if (
      error.error &&
      typeof error.error === 'string' &&
      error.error.length > 2000
    ) {
      // don't show message if it is too long to prevent chrome freeze.
      this.alert.error();
    } else if (error.error) {
      const errorInfos: string[] = [];
      for (const value of Object.values(error.error || {})) {
        if (typeof value === 'string') {
          errorInfos.push(value);
        } else if (Array.isArray(value)) {
          const biLangError = value
            .filter((i) =>
              Object.keys(i).includes(this.translate.currentLang),
            )
            .map((error) => error[this.translate.currentLang]);
          if (biLangError.length) {
            errorInfos.push(...biLangError);
          }
          errorInfos.push(
            ...value.filter((i) => typeof i === 'string'),
          );
        }
      }
      if (errorInfos.length > 200) {
        // don't show message if it is too long to prevent chrome freeze.
        this.alert.error();
      } else {
        this.alert.error(errorInfos.join('\n'));
      }
    } else {
      this.alert.error();
    }
  }

  @Action(SetWidgets)
  setWidget(ctx: StateContext<MemoDetail>, action: SetWidgets) {
    ctx.setState(
      patch({
        upload: patch({
          signature_position: [...action.widget],
        }),
      }),
    );
  }

  @Action(UpdateWidget)
  updateWidget(ctx: StateContext<MemoDetail>, action: UpdateWidget) {
    const state = ctx.getState();
    if (!state.upload) {
      throw new Error('Invalid action, upload not found');
    }
    state.upload.signature_position[action.index] = action.widget;
    ctx.setState(
      patch({
        upload: state.upload,
      }),
    );
  }
}

export class MemoDetailSelectors {
  static fullDetail = createSelector(
    [MemoCreationState],
    (state) => state as MemoDetail,
  );

  static slices =
    createPropertySelectors<MemoDetail>(MemoCreationState);

  static deepSlice = createDeepPropSelector(MemoCreationState);

  static uploadState(controlName = 'upload') {
    return createSelector(
      [MemoCreationState],
      (state) => state[controlName] as UploadMemoPatch,
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static widgets(uploadControlName = 'upload') {
    return createPickSelector(this.uploadState(uploadControlName), [
      'signature_position',
    ]);
  }
}

@State<MemoPasswordPopUp>({
  name: 'memoPasswordPopUp',
  defaults: {
    show: false,
    password: null,
    isCreator: false,
    memoId: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class MemoPasswordPopupState {
  @Action(ShowMemoPasswordPopup)
  showMemoPasswordPopup(
    { patchState }: StateContext<MemoPasswordPopUp>,
    {
      isCreator,
      memoId,
    }: { isCreator: boolean; memoId: number | null },
  ) {
    patchState({
      show: true,
      isCreator: isCreator,
      memoId: memoId,
    });
  }

  @Action(CloseMemoPasswordPopup)
  closeMemoPasswordPopup(
    { patchState }: StateContext<MemoPasswordPopUp>,
    { password }: { password: string | null },
  ) {
    patchState({
      show: false,
      password: password,
      isCreator: false,
      memoId: null,
    });
  }

  @Action(ClearMemoPasswordPopup)
  clearMemoPasswordPopup({
    setState,
  }: StateContext<MemoPasswordPopUp>) {
    setState({
      show: false,
      password: null,
      isCreator: false,
      memoId: null,
    });
  }
}
