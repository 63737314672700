<div class="background-blur">
  <!-- Top Bar -->
  <div class="top-bar">
    <h3 class="title-text">{{ title || ('MEMOS.UNTITLED'| translate) }}</h3>
    <div class="d-flex gap-4 gap-lg-5">
      <ng-container *ngIf="pdfFile">
        <div class="pointer"
            (click)="downloadDocument()">
          <svg stroke="#FFFFFF"
              stroke-width="1.5"
              width="21"
              height="21">
            <use height="21"
                width="21"
                href="assets/images/icons/arrows/arrow-down-to-line.svg#arrow-down-to-line">
            </use>
          </svg>
        </div>
        <div class="pointer"
            (click)="printDocument()">
          <svg stroke="#FFFFFF"
              stroke-width="1.5"
              width="21"
              height="21">
            <use height="21"
                width="21"
                href="assets/images/icons/devices/printer.svg#printer">
            </use>
          </svg>
        </div>
      </ng-container>
      <div *ngIf="showingAttachment"
          class="position-relative pointer"
          (click)="goToAttachment()">
        <svg stroke="#FFFFFF"
            stroke-width="1.5"
            width="21"
            height="21">
          <use height="21"
              width="21"
              href="assets/images/icons/objects/paperclip.svg#paperclip">
          </use>
        </svg>
        <div *ngIf="memo?.attachment_count !== 0"
            class="notification-attachment badge rounded-pill text-white"
            [style.background-color]="'#EE2E22'">
          <span>{{ memo.attachment_count }}</span>
        </div>
      </div>
      <div class="pointer"
          (click)="close()">
        <svg stroke="#FFFFFF"
            stroke-width="1.5"
            width="21"
            height="21">
          <use height="21"
              width="21"
              href="assets/images/icons/marks/x.svg#x">
          </use>
        </svg>
      </div>
    </div>
  </div>

  <!-- PDF Viewer Body -->
  <div #pdfView
      class="pdf-preview"
      (onResize)="onPdfViewResized($event)">
    <div *ngIf="!pdfFile"
        class="no-pdf">
      <div class="text-center"
          style="max-width: 60%">
        <svg class="icon-empty-page"
            height="100"
            viewBox="0 0 380 150"
            width="">
          <use href="assets/images/pictograms/paint.svg#paint"
              height="124"
              width=""
              y="8"
              fill="#EAEAED">
          </use>
          <use href="assets/images/pictograms/doc.svg#doc"
              height="140"
              width=""
              x="127">
          </use>
        </svg>
        <ng-container *ngIf="memo?.password_protected; else noDataTemplate">
          <div>
            <h5 class="fw-bold mb-1">
              {{ 'MEMOS.This document cannot be viewed' | translate }}
            </h5>
            <p class="text-gray-neutrals mb-0">
              {{ 'MEMOS.You must enter your password first. Click on View Document Details' | translate }}
            </p>
          </div>
        </ng-container>
        <ng-template #noDataTemplate>
          <div>
            <h5 class="fw-bold mb-1 mt-2">
              <span>{{ 'PREVIEW-PDF.TITLE' | translate }}</span>
            </h5>
            <p class="text-gray-neutrals mb-0">
              {{
                ('PREVIEW-PDF.SUB-TITLE' | translate).replaceAll('$MODULE', (module | translate))
              }}
            </p>
          </div>
        </ng-template>
      </div>
      <ng-container *ngTemplateOutlet="footer; context: { $implicit: 0 }"></ng-container>
    </div>
    <pdf-viewer *ngIf="pdfFile && !isRefreshPDF"
        class="w-100 h-100"
        [ngClass]="isRendered ? 'opacity-100' : 'opacity-0'"
        [src]="{
          url: pdfFile,
          httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders : undefined)
        }"
        [(page)]="currentDocumentPage"
        [render-text]="false"
        [original-size]="false"
        [stick-to-page]="false"
        [show-all]="true"
        [zoom]="1"
        [zoom-scale]="'page-fit'"
        [c-maps-url]="'/assets/cmaps/'"
        (after-load-complete)="onLoadPdfComplete($event)"
        (page-rendered)="pageRendered($event)">
    </pdf-viewer>
    <ng-container *ngIf="pdfFile && !isRefreshPDF">
      <ng-container *ngTemplateOutlet="footer; context: { $implicit: right+40 }"></ng-container>
    </ng-container>
    <ng-template #pdfIcon
      let-right>
      <div *ngIf="!pdfFile || !this.isDocumentLoadingPage"
          class="icon-pdf d-flex flex-row flex-md-column"
          [ngStyle]="{
          'right.px': right,
        }">
        <div (click)="close()">
          <a class="icon-circle btn-close-pdf"
              href="javascript:void(0)">
            <svg height="15"
                stroke="#F53636"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/marks/x.svg#x">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="pdfFile"
            class="order-first order-md-last pointer">
          <a class="icon-circle"
              href="javascript:void(0)"
              (click)="downloadDocument()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/arrows/arrow-down-to-line.svg#arrow-down-to-line">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="pdfFile"
            class="order-first order-md-last pointer">
          <a class="icon-circle"
              href="javascript:void(0)"
              (click)="printDocument()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/devices/printer.svg#printer">
              </use>
            </svg>
          </a>
        </div>
        <div *ngIf="showingAttachment"
            class="order-first order-md-last pointer">
          <a class="icon-circle rounded-circle"
              href="javascript:void(0)"
              (click)="goToAttachment()">
            <svg height="15"
                stroke="#55596D"
                width="15"
                stroke-width="3">
              <use height="15"
                  width="15"
                  href="assets/images/icons/objects/paperclip.svg#paperclip">
              </use>
            </svg>
          </a>
          <div *ngIf="memo?.attachment_count !== 0 || memo?.stc_attachment_count !== 0"
              class="notification-attachment badge rounded-pill text-white"
              [style.background-color]="'#EE2E22'">
            <span>{{ memo.attachment_count + (memo.stc_attachment_count ?? 0) }}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ngx-spinner bdColor="transparent"
        color="#fff"
        name="loadingPdfPreviewStd4"
        size="medium"
        type="ball-clip-rotate"
        [fullScreen]="false"
        [showSpinner]="!isRendered || isRefreshPDF"
        [zIndex]="2">
    </ngx-spinner>
    <ng-template #footer
      let-right>
      <div *ngIf="!pdfFile || !this.isDocumentLoadingPage"
          class="footer-pdf row m-0 align-items-center p-3"
          [ngClass]="isRendered ? 'opacity-100' : 'opacity-0'"
          [ngStyle]="{
          'width': this.pdfFile ? pageWidth[currentDocumentPage] + 'px': '100%',
          'bottom': this.pdfFile ? '20px' : '0px',
          'right': right + 'px',
          'max-width': this.pdfFile ? '': '550px'
        }">
        <div *ngIf="pdfFile"
            class="col-auto p-0 page-count d-flex align-items-center">
          <span>
            <app-input-type-number [min]="1"
                [max]="totalDocumentPage"
                [(model)]="currentDocumentPage"
                (modelChange)="currentDocumentPageChange()"></app-input-type-number>
          </span>
          <span class="ms-2">
            {{ 'UPLOAD.OF' | translate }} {{ totalDocumentPage }} {{ 'MEMOS.pages' | translate }}
          </span>
        </div>
        <div *ngIf="showingViewDetail"
            class="col text-end p-0">
          <button class="btn btn-std-prim mx-1 btn-std-color-1-primary"
              (click)="goToDetail()"
              [ngStyle]="{'font-size': this.pdfFile && pageWidth[currentDocumentPage] < 385 ? '0.7rem': '',}">
            <svg-icon [src]="detailBtnIconPath"
                [svgStyle]="{
                  'stroke': 'var(--std-btn-text-primary)',
                  'width.px': 15, 
                  'height.px': 15, 
                  'margin-bottom.px': 2
                }">
            </svg-icon>
            {{ detailBtnText | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>